<template>
  <div id="wrapper">

    <nav class="navbar bg-transparent position-absolute w-100" style="z-index: 1">
      <div class="container-fluid">
        <div class="navbar-brand bars" @click="changeScreen(0)">
          <i class="ph-arrow-left text-white me-3 nav-icon circle p-2 bg-orange-secondary"></i>
        </div>

        <span class="navbar-left">
          <div class="navbar-brand">
            <span style="display: -webkit-inline-box;">
              <h4 style="margin-top: 0.2rem">
              <i class="ph-bell-ringing text-white me-3 nav-icon circle p-2 bg-orange-secondary"></i>
              </h4>
            </span>
          </div>      
        </span>

        <div class="divider mt-4 d-none"></div>
      </div>
    </nav>

    <div class="backdrop" @click="close()"></div>

    <div class="content bg-white position-fixed bottom-0 w-100" style="z-index: 1; border-top-right-radius: 5%; border-top-left-radius: 5%; border: 1px solid #eee;">
        <div class="card-header bg-transparent border-0 text-center pt-4 pb-1">
          <div class="row">
            <h6 class="text-uppercase fw-bold text-orange" style="letter-spacing: 1px">Active Delivery</h6>
          </div>

          <div class="row" v-if="delivery_data.delivery_from">
            <div class="col-12 d-flex mt-3 mb-3">
              <font-awesome-icon class="text-success me-4" :icon="['fa', 'circle-notch']"></font-awesome-icon>
              <p class="text-uppercase mb-0" style="font-weight: 500; letter-spacing: .3px">From {{ delivery_data.delivery_from }}</p>
            </div>

            <div class="col-12 d-flex mt-3 mb-3">
              <font-awesome-icon class="text-success me-4" :icon="['fa', 'location-arrow']"></font-awesome-icon>
              <p class="text-uppercase mb-0" style="font-weight: 500; letter-spacing: .3px">To {{ delivery_data.delivery_to }}</p>
            </div>

            <div class="col-12 d-flex mt-3 mb-3">
              <font-awesome-icon class="text-success me-4" :icon="['fa', 'user']"></font-awesome-icon>
              <p class="text-uppercase mb-0" style="font-weight: 500; letter-spacing: .3px"> {{ delivery_data.name }}</p>
            </div> 
            
            <div class="col-12 d-flex mt-3 mb-3">
              <font-awesome-icon class="text-success me-4" :icon="['fa', 'phone-alt']"></font-awesome-icon>
              <p class="text-uppercase mb-0" style="font-weight: 500; letter-spacing: .3px"> +254 {{ delivery_data.phone_number }}</p>
            </div>                        
          </div>
        </div>

        <div class="card-body" v-if="!delivery_data.delivery_from">
          <no-data class="mt-4" :message="'No Active Delivery'"></no-data>
        </div>

        <div class="card-body" v-if="delivery_data.delivery_from">
          <div class="row">
            <div class="col-8">
              <button class="btn btn-dark w-100" v-if="delivery_data.status == 'driver en-route'" @click="changeStatus('dispatched')">
                Start Ride <font-awesome-icon class="ms-2" :icon="['fa', 'angle-right']"></font-awesome-icon>
              </button>
              
              <button class="btn btn-success w-100" v-if="delivery_data.status == 'dispatched'" @click="changeStatus('reached')">
                Complete Delivery <font-awesome-icon class="ms-2" :icon="['fa', 'angle-right']"></font-awesome-icon>
              </button>

              <button class="btn bg-orange text-white w-100" v-if="delivery_data.status == 'reached'" @click="confirmPayment()">
                <font-awesome-icon class="me-2" :icon="['fa', 'dollar-sign']"></font-awesome-icon> Confirm Payment 
              </button>

              <button class="btn bg-success text-white w-100" v-if="delivery_data.status == 'waiting confirmation'">
                Complete <font-awesome-icon class="me-2" :icon="['fa', 'check-circle']"></font-awesome-icon>
              </button>
              <p class="ms-auto text-uppercase fw-bold text-orange mb-0 mt-2">
                <span class="mb-0 me-2">&bull;</span> {{ delivery_data.status }}
              </p>              
            </div>

            <div class="col-4">
              <button class="btn btn-danger" @click="changeStatus('cancelled')">
                 Cancel <font-awesome-icon class="ms-2" :icon="['fa', 'times']"></font-awesome-icon>
              </button>
                             
            </div>
          </div>
        </div>
    </div>

    <div id="map" style="height: 100%"></div>       
  </div>complete
</template>

<style lang="scss" scoped>
  @import '../../assets/scss/layout/_navbar.scss';
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

    ul.timeline {
      list-style-type: none;
      position: relative;
    }

    ul.timeline:before {
      content: ' ';
      background: #d4d9df;
      display: inline-block;
      position: absolute;
      left: 29px;
      width: 2px;
      height: 100%;
      z-index: 400;
    }

    ul.timeline > li {
      margin: 20px 0;
      padding-left: 20px;
    }

    ul.timeline > li:before {
      content: ' ';
      background: white;
      display: inline-block;
      position: absolute;
      border-radius: 50%;
      border: 3px solid #22c0e8;
      left: 20px;
      width: 20px;
      height: 20px;
      z-index: 400;
    }

    ul.timeline > li.done:before {
      content: ' ';
      background: #198754;
      display: inline-block;
      position: absolute;
      border-radius: 50%;
      border: 3px solid #198754;
      left: 20px;
      width: 20px;
      height: 20px;
      z-index: 400;
    }
</style>

<script>
  import mapFunctions from '../../mixins/mapFunctions'
  import NoData from '../../components/NoData'

  export default {
    name: 'Home',
    mixins: [mapFunctions],  
    components:  {
      NoData,
    },
    data() {
      return {
        delivery_data: {}
      }
    },
    watch: {
      user: function() {
        this.ifNotLoggedIn()
      }
    },
    computed: {
      user () {
        return this.$store.state.user
      },
      loading () {
        return this.$store.state.loading
      }       
    },    
    mounted() {
      this.$store.dispatch("getUser")
      this.getLatestDelivery()
    },
    methods: {
      ifNotLoggedIn() {
        if( !this.user.name) {
          this.$router.push({name: 'Login'});
        } 
      },
      getLatestDelivery() {
        this.axios.get('api/latest-delivery-driver').then(response => {
          this.delivery_data = response.data.data
          console.log(response.data.data);
        })
      },
      formatNumber(num) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      }, 
      changeStatus(value) {
        let data = {
          status: value
        }
        this.axios.put('api/delivery/' + this.delivery_data.id, data).then(() => {
          this.delivery_data.status = value
        }).catch(errors => {
          console.log(errors);
        })
      },
      confirmPayment(value) {
        let data = {
          user_id: this.user.id,
        }

        this.axios.put('api/confirm-payment/' + this.delivery_data.id, data).then(() => {
          this.delivery_data.status = value
        }).catch(errors => {
          console.log(errors);
        })
      }      
    }    
  }
</script>
